<template>
  <el-row type="flex" justify="center" align="center">
    <el-col :xs="24" :sm="10" :md="7">
      <el-card v-loading="loading.ip">
        <div slot="header" style="text-align: center">
          <img
            src="@/assets/img/svg/logo-aliaddo.svg"
            alt="ALIADDO"
            width="200"
            height="45"
          />
        </div>
        <transition-group name="fadeLeft" tag="el-form">
          <el-form
            v-if="mode === 'login'"
            :key="1"
            ref="formLogin"
            v-cus-loading="loading.login"
            :model="formLogin.model"
            :rules="formLogin.rules"
            @keyup.enter.native="login"
          >
            <el-form-item :label="$t('username')" prop="username">
              <el-input v-model="formLogin.model.username"></el-input>
            </el-form-item>
            <el-form-item :label="$t('password')" prop="password">
              <el-input
                v-model="formLogin.model.password"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('language')">
              <el-select v-model="language">
                <el-option label="Español" value="es"></el-option>
                <el-option label="English" value="en"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button
                size="mini"
                type="primary"
                :loading="loading.login"
                style="width: 90%"
                @click.prevent="onLogin"
              >
                Iniciar sesión
              </el-button>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button size="mini" type="text" @click.prevent="recovery">
                <i class="fa fa-lock m-r-5"></i>&nbsp;Olvidaste tu contraseña?
              </el-button>
            </el-form-item>
            <el-form-item style="text-align: center">
              {{ browser.os }}, {{ browser.name | capital }} - Ip {{ ip }}
            </el-form-item>
          </el-form>
          <el-form
            v-else
            :key="2"
            ref="formProfile"
            v-cus-loading="loading.profile"
            :model="formProfile.model"
            :rules="formProfile.rules"
            @keyup.enter.native="onLogin"
          >
            <el-form-item :label="$t('role')" prop="roleId">
              <el-select
                v-model="formProfile.model.roleId"
                :placeholder="$tc('role')"
              >
                <el-option
                  v-for="(option, index) in list.roles"
                  :key="index"
                  :value="option.roleId"
                  :label="option.roleName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="text-align: right">
              <el-button
                size="mini"
                type="text"
                @click.prevent="mode = 'login'"
              >
                {{ $tc('cancel') }}
              </el-button>
              <el-button
                size="mini"
                type="primary"
                :loading="loading.profile"
                @click.prevent="onContinue"
              >
                {{ $tc('continue') }}
              </el-button>
            </el-form-item>
          </el-form>
        </transition-group>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import axios from 'axios'
import { detect } from 'detect-browser'
import deviceDetect from 'device-detect'
import jwtDecode from 'jwt-decode'
import { isLoggedIn } from '../../assets/js/auth'
import { i18n } from '../../i18n'
import Router from '../../router'

export default {
  name: 'Login',
  data() {
    const validateEmailIsAliaddo = (rule, value, callback) => {
      /* eslint-disable-next-line no-useless-escape */
      const regularExp =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (value !== '') {
        if (value.includes('@aliaddo.com') && regularExp.test(value)) {
          callback()
        } else if (!value.includes('.com') && !regularExp.test(value)) {
          value = value.concat('@aliaddo.com')
          callback()
        } else if (regularExp.test(value)) {
          callback(new Error(rule.message))
        }
      }
    }

    return {
      formProfile: {
        model: {
          roleId: '',
        },
        rules: {
          roleId: [
            {
              required: true,
              message: 'Seleccione rol',
              trigger: ['blur', 'change'],
            },
          ],
        },
      },
      list: {
        roles: [],
      },
      formLogin: {
        model: {
          username: '',
          password: '',
          token: '',
        },
        rules: {
          // username: [{ required: true, message: 'Ingresa tu email', trigger: 'blur' }, { type: 'email', message: i18n.t('noAliaddoEmail') }],
          username: [
            { required: true, message: 'Ingresa tu email', trigger: 'blur' },
            {
              type: 'email',
              message: i18n.t('noAliaddoEmail'),
              validator: validateEmailIsAliaddo,
            },
          ],
          password: [
            {
              required: true,
              message: 'Ingresa tu contraseña',
              trigger: 'blur',
            },
          ],
        },
      },
      mode: 'login',
      loading: {
        ip: false,
        login: false,
        role: false,
      },
      language: 'es',
      browser: detect(),
      device: deviceDetect().device,
      ip: '',
    }
  },
  mounted() {
    const _this = this
    if (isLoggedIn()) {
      Router.push({ name: 'home' })
    } else {
      _this.getIp()
    }
  },
  methods: {
    onLogin() {
      const _this = this
      _this.$refs.formLogin.validate(valid => {
        if (valid) {
          _this.loading.login = true

          // -- CREDENTIAL
          const credential = {
            username: _this.formLogin.model.username,
            password: _this.formLogin.model.password,
          }

          // /-- CREDENTIAL
          const headers = {
            Ip: _this.ip,
            Os: _this.browser.os,
            Browser: _this.browser.name,
            Device: _this.device,
          }
          _this.$http
            .post('auth', credential, { headers })
            .then(
              result => {
                _this.formLogin.model.token = result.body.el_rey_mono_kung_fu
                _this.list.roles = result.body.roles
                _this.mode = 'profile'
                if (_this.list.roles.length < 2) {
                  setTimeout(() => {
                    _this.formProfile.model.roleId = _this.list.roles[0].roleId
                    _this.onContinue()
                  }, 500)
                }
              },
              result => {
                _this.loading.role = false
              },
            )
            .finally(_ => (_this.loading.login = false))
        }
      })
    },
    onContinue() {
      const _this = this
      _this.$refs.formProfile.validate(valid => {
        if (valid) {
          _this.loading.profile = true
          _this.$http
            .post('auth/continue', {
              token: _this.formLogin.model.token.accessToken,
              roleId: _this.formProfile.model.roleId,
            })
            .then(
              response => {
                localStorage.setItem(
                  'el_rey_mono_kung_fu',
                  response.body.el_rey_mono_kung_fu,
                )
                localStorage.setItem(
                  'el_rey_mono_kung_fu_expires_in',
                  response.body.el_rey_mono_kung_fu_expires_in,
                )

                const decodeToken = jwtDecode(response.body.el_rey_mono_kung_fu)

                localStorage.setItem('masters', JSON.stringify(decodeToken))

                _this.$store.commit('setLogin')
                _this.$store.commit(
                  'setSetting',
                  JSON.parse(localStorage.getItem('masters')),
                )

                if (+_this.formProfile.model.roleId === 4) {
                  Router.push({ name: 'HomeSellers' })
                } else if (+_this.formProfile.model.roleId === 3) {
                  Router.push({ name: 'HomeSupport' })
                } else {
                  Router.push({ name: 'Home' })
                }
              },
              response => {
                _this.loading.role = false
              },
            )
        }
      })
    },
    recovery() {
      Router.push({ name: 'Recovery', params: { service: 'hub' } })
    },
    getIp() {
      const _this = this
      axios
        .get('https://api.ipify.org')
        .then(response => {
          _this.ip = response.data
        })
        .catch(e => {
          _this.$message.error(e)
        })
    },
    getOs() {
      const { userAgent } = window.navigator
      const { platform } = window.navigator
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      const iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
      } else if (/Android/.test(userAgent)) {
        os = 'Android'
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
      }

      return os
    },
  },
}
</script>
<style scoped="">
.el-card__header {
  background-color: white !important;
  padding: 0%;
}
</style>
